"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommentBody = exports.CommentActions = exports.Comment = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
var _RatingStyle = require("../../rating/Rating.style.js");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _styledComponents2 = require("../../styled-components");
var _media = require("../../styled-components/media");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CommentBody = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-body', props.className)
  };
}).withConfig({
  displayName: "Commentstyle__CommentBody",
  componentId: "sc-1r9t928-0"
})([".stackend &{background-color:", ";padding:20px;position:relative;border-radius:", " 0px ", " ", ";&:after{content:'';position:absolute;display:block;width:0px;border-style:solid;border-width:0px 0px 20px 20px;top:-19px;right:0%;margin-left:0px;border-color:", " transparent;}&.stackend-is-default-user{background-color:", ";border-radius:0px ", " ", " ", ";&:after{left:0%;border-width:0px 20px 20px 0px;border-color:", " transparent;}}.stackend-user-small{float:left;margin-right:", ";}.stackend-author-name{overflow:hidden;margin-bottom:", ";margin-right:", ";}iframe,.stackend-rich-content img{display:block;clear:both;margin-bottom:10px;margin-top:10px;max-width:820px;border-radius:calc(", " * 0.5);", "{max-width:100%;}}.xcap-responsive-subsite{position:relative;z-index:99;display:inline-block;height:0;width:100%;padding-bottom:56.4% !important;margin:5px 0;iframe{position:absolute;top:0;left:0;width:100%;height:100%;max-width:unset;margin-bottom:0;padding-top:0;}}", "{margin-left:2em;}.stackend-shop-product-module-horizontal{display:unset;}", ";}"], function (props) {
  return props.theme.shopifyMainColor || '#ccc';
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyMainColor || '#ccc';
}, function (props) {
  return props.theme.shopifyAIColor;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyAIColor;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, sc.TabletAndMobile, _RatingStyle.Rating, function (props) {
  return !!props.theme.CommentBody && props.theme.CommentBody(props);
});
exports.CommentBody = CommentBody;
var CommentActions = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-actions', props.className)
  };
}).withConfig({
  displayName: "Commentstyle__CommentActions",
  componentId: "sc-1r9t928-1"
})([".stackend &{display:grid;grid-template-columns:max-content min-content min-content min-content;justify-content:end;width:100%;align-items:center;flex-direction:row;gap:", ";", "{.stackend-reply-comment-button span{display:none;}}}"], function (props) {
  return props.theme.margins.small;
}, _media.media.mobilePortrait);
exports.CommentActions = CommentActions;
var Comment = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment', props.className)
  };
}).withConfig({
  displayName: "Commentstyle__Comment",
  componentId: "sc-1r9t928-2"
})([".stackend &{overflow:hidden;box-sizing:border-box;width:100%;position:relative;opacity:1;margin-bottom:20px;&.stackend-is-default-user{}&.stackend-is-notification{img{padding-top:0;margin:0;}}&.stackend-comment-has-rating{grid-template-columns:min-content auto auto 1fr auto;grid-template-areas:'author-image author-name rating created-date actions' '- text text text text';", "{grid-template-columns:min-content 1fr auto auto;grid-template-areas:'author-image author-name author-name actions' '- rating rating rating' '- text text text';}}&.stackend-highlight{@keyframes fadeIn{0%{opacity:0;}100%{opacity:1;}}animation:fadeIn 2s;}&.stackend-comment-selected{background:", ";}.stackend-comment-created-date{display:grid;align-items:end;}", "{grid-area:rating;}", "{grid-area:text;}", "{grid-area:actions;align-self:start;}", "{", "{display:none;}}.stackend-comment-content-wrapper{-webkit-text-decoration:none;text-decoration:none;border-radius:calc(", " * 0.5);display:block;}.stackend-comment-subject{font-weight:bold;}", ";}"], _media.media.mobileScreen, sc.discreteBackgroundColor, _RatingStyle.Rating, CommentBody, CommentActions, _media.media.mobilePortrait, _styledComponents2.CreateDate, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return typeof props.theme.Comment === 'function' && props.theme.Comment(props);
});
exports.Comment = Comment;