"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CommentsAuthorWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CommentsAuthorWrapper = _styledComponents.default.div.attrs({
  className: 'stackend-author-wrapper'
}).withConfig({
  displayName: "CommentsAuthorWrapper",
  componentId: "sc-o74ung-0"
})([".stackend &{display:grid;grid-template-columns:max-content auto;grid-template-rows:max-content;grid-column-gap:10px;border-bottom:1px solid #ffffff99;padding:20px;.stackend-just-suffix{font-size:12px;text-transform:uppercase;font-weight:300;margin-top:3px;}", ";}"], function (props) {
  return !!props.theme.CommentsAuthorWrapper && props.theme.CommentsAuthorWrapper(props);
});
exports.CommentsAuthorWrapper = CommentsAuthorWrapper;
var _default = CommentsAuthorWrapper;
exports.default = _default;