"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TailorThrobberStyle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var TailorThrobberStyle = _styledComponents.default.div.withConfig({
  displayName: "TailorThrobberstyle__TailorThrobberStyle",
  componentId: "sc-129y28u-0"
})([".stackend &{position:relative;margin:0 auto;width:80%;aspect-ratio:1;display:grid;grid-template-columns:repeat(20,1fr);grid-template-rows:repeat(20,1fr);place-items:center;overflow:hidden;-webkit-mask-image:radial-gradient(circle,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 80%);-webkit-mask-repeat:no-repeat;-webkit-mask-size:cover;mask-image:radial-gradient(circle,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 80%);mask-repeat:no-repeat;mask-size:cover;.dot{display:block;width:4px;height:4px;background-color:#00bcd4;border-radius:50%;opacity:0.3;animation:pulse infinite,move infinite;}@keyframes pulse{0%,100%{transform:scale(1);opacity:0.2;}25%{transform:scale(2);opacity:0.8;}50%{transform:scale(1.5);opacity:0.5;}75%{transform:scale(1.8);opacity:0.7;}}@keyframes move{0%,100%{transform:translate(0,0);}25%{transform:translate(12px,-12px);}50%{transform:translate(-15px,15px);}75%{transform:translate(-10px,-20px);}}}"]);
exports.TailorThrobberStyle = TailorThrobberStyle;