"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Initials = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Initials = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-initials', props.className)
  };
}).withConfig({
  displayName: "Initials",
  componentId: "sc-k19g3h-0"
})([".stackend &{display:flex;align-items:center;justify-content:center;margin:0;background:", ";color:black;border-radius:calc(", " * 0.5);text-align:center;border:2px solid;width:100%;height:100%;aspect-ratio:1 / 1;&.stackend-is-default-user{background-color:", ";}", ";}"], function (props) {
  return props.theme.shopifyMainColor;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyAIColor;
}, function (props) {
  return !!props.theme.Initials && props.theme.Initials(props);
});
exports.Initials = Initials;
var _default = Initials;
exports.default = _default;