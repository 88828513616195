"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CommentEditor = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _RichContentEditor = _interopRequireDefault(require("../Editor/RichContentEditor.js"));
var _RichContentEditor2 = require("../../editor/RichContentEditor2.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CommentEditor = _styledComponents.default.form.attrs({
  className: 'stackend-comment-editor'
}).withConfig({
  displayName: "CommentEditor",
  componentId: "sc-1sassr3-0"
})([".stackend &{width:100%;position:relative;display:flex;flex-direction:column;textarea{min-width:100%;max-width:100%;min-height:100px;}", "{margin-right:3px;}", "{margin-top:0;}.stackend-review-score{margin:", " 0;display:inline-flex;align-items:center;}.ql-editor{&:focus-visible{outline:unset;outline-offset:unset;box-shadow:unset;}}", ";}"], _RichContentEditor.default, _RichContentEditor2.RichContentEditor2, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return !!props.theme.CommentEditor && props.theme.CommentEditor(props);
});
exports.CommentEditor = CommentEditor;
var _default = CommentEditor;
exports.default = _default;