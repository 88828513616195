"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ViewForumThread = exports.ForumEntry = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ViewForumThread = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-forum-grid-wrapper', props.className)
  };
}).withConfig({
  displayName: "ViewForumThread",
  componentId: "sc-14wsns-0"
})([".stackend &{width:100%;column-count:3;gap:10px;}"]);
exports.ViewForumThread = ViewForumThread;
var ForumEntry = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-forum-entry', props.className)
  };
}).withConfig({
  displayName: "ViewForumThread__ForumEntry",
  componentId: "sc-14wsns-1"
})([".stackend &{opacity:0;animation:fadeIn 2.5s forwards;display:grid;grid-template-rows:1fr auto;break-inside:avoid;grid-row-gap:1em;margin-bottom:", ";background-color:", ";padding:", " ", ";@keyframes fadeIn{0%{opacity:0;}100%{opacity:1;}}}"], function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
});
exports.ForumEntry = ForumEntry;